@import "/node_modules/bootstrap-scss/bootstrap.scss";
@import "/node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css";
@import "/node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css";         
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import "~animate-scss/animate.css";
@import "color-10";
@import "font-awesome.scss";
@import "themify.scss";
@import "flaticon.scss";
@import "../css/keyframes.css";
@import "~animate.css/animate.min.css";
@import '/node_modules/ngx-toastr/toastr';
